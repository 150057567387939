import React from 'react';

import { Redirect } from '@reach/router';


const Pferd = () => {
  return (
    <Redirect to={'/'} />
  )
}

export default Pferd;
